'use strict';

var providerUrl = "http://gvg5/nlnl/json";
// var providerUrl = "https://groenevakantiegids.nl/nlnl/json";

angular.module('GVGMobile.controllers.Main', [])
    .factory('MyProvider', ['$resource', function($resource){
        return {
            arr : $resource(providerUrl, {methode: '@methode', id: '@id', user_lang: '@user_lang',filter: '@filter', callback: "JSON_CALLBACK"},
                {'get': { method:'JSONP', isArray:true}}),
            obj : $resource(providerUrl, {methode: '@methode', id: '@id', user_lang: '@user_lang', filter: '@filter', callback: "JSON_CALLBACK"},
                {'get': { method:'JSONP', isArray:false}})
        }
    }])
    .factory('MyLocationProvider', ['$resource', function($resource){
        return $resource(providerUrl, {methode: '@methode', lat: '@lat', lon: '@lon', user_lang: '@user_lang', callback: "JSON_CALLBACK"},
            {'get': { method:'JSONP', isArray:true}})
    }])
    .filter('html',function($sce){
        return function(input){
            return $sce.trustAsHtml(input);
        }
    })

    .controller('HomeCtrl',['$scope', 'MyProvider', '$rootScope', function($scope, MyProvider, $rootScope){
        $rootScope.filterwarning = false;
        $rootScope.pageclass = "home";

        MyProvider.obj.get({methode: "home", user_lang: $rootScope.$storage.user_lang}, function(data){
            if(data["main_photo"]) $scope.main_photo = data["main_photo"];
            if(data["slogans"]) $scope.slogans = data["slogans"];
            $rootScope.loading = false;
        });
    }])

    .controller('MainCtrl', ['$scope', 'MyProvider', '$rootScope', '$routeParams',
        function ($scope,MyProvider, $rootScope, $routeParams) {
            var filter = false;
            $rootScope.pageclass = "landen";

            if($rootScope.$storage.filter){
                filter = JSON.stringify($rootScope.$storage.filtersettings);
                $rootScope.filterwarning = true;
            } else {
                $rootScope.filterwarning = false;
            }

            var now = Date.now();

            var setTotals = function(filter){
                MyProvider.obj.get({user_lang: $rootScope.$storage.user_lang, filter: filter}, function(data){
                    if(filter){
                        $rootScope.$storage.totals_filtered = data;
                        $rootScope.$storage.totals_filtered_refreshdate = now;
                    } else {
                        $rootScope.$storage.totals = data;
                        $rootScope.$storage.totals_refreshdate = now;
                    }
                    if($routeParams.countryId !== undefined){
                        if(filter){
                            $scope.items = $rootScope.$storage.totals_filtered[$routeParams.countryId];
                        } else {
                            $scope.items = $rootScope.$storage.totals[$routeParams.countryId];
                        }
                        if(Object.size($scope.items.regions) == 0) {
                            //no regions, so we load an accom list
                            MyProvider.obj.get({
                                id: $routeParams.countryId,
                                methode: "country_accoms",
                                user_lang: $rootScope.$storage.user_lang,
                                filter: filter
                            }, function (data) {
                                $scope.accoms = data["accoms"];
                            });
                        }
                    } else {
                        if(filter){
                            $scope.items = $rootScope.$storage.totals_filtered;
                        } else {
                            $scope.items = $rootScope.$storage.totals;
                        }
                    }
                    $rootScope.loading = false;
                });
            };

            if(filter){
                //get filtered totals data
                if(Object.size($rootScope.$storage.totals_filtered.length) > 0){
                    //are they still valid?
                    if(now - $rootScope.$storage.totals_filtered_refreshdate > 86400000){
                        setTotals(filter);
                    } else {
                        if($routeParams.countryId !== undefined){
                            $scope.items = $rootScope.$storage.totals_filtered[$routeParams.countryId];
                            if(Object.size($scope.items.regions) == 0) {
                                //no regions, so we load an accom list
                                MyProvider.obj.get({
                                    id: $routeParams.countryId,
                                    methode: "country_accoms",
                                    user_lang: $rootScope.$storage.user_lang,
                                    filter: filter
                                }, function (data) {
                                    $scope.accoms = data["accoms"];
                                    $rootScope.loading = false;
                                });
                            }
                        } else {
                            $scope.items = $rootScope.$storage.totals_filtered;
                            $rootScope.loading = false;
                        }
                    }
                } else {
                    setTotals(filter);
                }
            } else {
                if(Object.size($rootScope.$storage.totals) > 0){
                    if(now - $rootScope.$storage.totals_refreshdate > 86400000){
                        setTotals(filter);
                    } else {
                        if($routeParams.countryId !== undefined){
                            $scope.items = $rootScope.$storage.totals[$routeParams.countryId];
                            if(Object.size($scope.items.regions) == 0) {
                                //no regions, so we load an accom list
                                MyProvider.obj.get({
                                    id: $routeParams.countryId,
                                    methode: "country_accoms",
                                    user_lang: $rootScope.$storage.user_lang,
                                    filter: filter
                                }, function (data) {
                                    $scope.accoms = data["accoms"];
                                    $rootScope.loading = false;
                                });
                            } else {
                                $rootScope.loading = false;
                            }
                        } else {
                            $scope.items = $rootScope.$storage.totals;
                            $rootScope.loading = false;
                        }
                    }
                } else {
                    setTotals(filter);
                }
            }
        }])

    .controller('RegionCtrl', ['$scope', '$routeParams', 'MyProvider','$rootScope',
        function($scope, $routeParams, MyProvider, $rootScope){
            var filter = false;
            $rootScope.pageclass = "region";

            if($rootScope.$storage.filter){
                filter = JSON.stringify($rootScope.$storage.filtersettings);
                $rootScope.filterwarning = true;
            } else {
                $rootScope.filterwarning = false;
            }
            MyProvider.obj.get({id: $routeParams.regionId, methode: "accoms", user_lang: $rootScope.$storage.user_lang, filter: filter}, function(data){
                if(data["region"]) $scope.region = data["region"];
                if(data["accoms"]) $scope.accoms = data["accoms"];
                if(data["country"]) $scope.country = data["country"];
                $rootScope.loading = false;
            });
        }])

    .controller('AccomCtrl', ['$scope', '$routeParams', 'MyProvider','$rootScope',
        function($scope, $routeParams, MyProvider, $rootScope){
            $rootScope.filterwarning = false;
            $rootScope.pageclass = "accom";

            var height = $(window).innerHeight() - 173;
            $scope.carHeight = {'height':height+'px'};

            $scope.getRoutedescription = function(lat,lon){
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(setLatLon);
                } else {
                    checkLatLon();
                }

                window.open("https://www.google.com/maps/dir/"+$rootScope.$storage.user_lat+","+$rootScope.$storage.user_lon+"/"+lat+","+lon);
            };

            function checkLatLon(){
                if($rootScope.$storage.user_lat){
                    var now = Date.now();
                    if(now - $rootScope.$storage.lat_lon_changed > 86400000){
                        //old lat lon, reset to null
                        $rootScope.$storage.user_lat = false;
                        $rootScope.$storage.user_lon = false;
                        $rootScope.$storage.lat_lon_changed = now;
                    }
                }
            }

            function setLatLon(position){
                $rootScope.$storage.user_lat = position.coords.latitude;
                $rootScope.$storage.user_lon = position.coords.longitude;
                $rootScope.$storage.lat_lon_changed = Date.now();
            }

            MyProvider.obj.get({id: $routeParams.accomId, methode: "accom", user_lang: $rootScope.$storage.user_lang}, function(data){
                $scope.accom = data;
                $rootScope.loading = false;
            });


        }])

    .controller('NearCtrl', ['$scope', 'MyLocationProvider','$rootScope',
        function($scope, MyLocationProvider, $rootScope){
            $rootScope.filterwarning = false;
            $rootScope.pageclass = "near";

            function getLocation(){
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition(showNearest,showError);
                } else {
                    $rootScope.loading = false;
                    $scope.error = "Geolocation is not supported by this browser.";
                }
            }

            function showError(error){
                switch(error.code)
                {
                    case error.PERMISSION_DENIED:
                        $scope.error = "User denied the request for Geolocation.";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        $scope.error = "Location information is unavailable."
                        break;
                    case error.TIMEOUT:
                        $scope.error = "The request to get user location timed out."
                        break;
                    case error.UNKNOWN_ERROR:
                        $scope.error = "An unknown error occurred."
                        break;
                }
                $rootScope.loading = false;
            }

            if (!Date.now) {
                Date.now = function now() {
                    return new Date().getTime();
                };
            }

            function showNearest(position){
                $rootScope.$storage.user_lat = position.coords.latitude;
                $rootScope.$storage.user_lon = position.coords.longitude;
                $rootScope.$storage.lat_lon_changed = Date.now();

                MyLocationProvider.get({lat: $rootScope.$storage.user_lat , lon: $rootScope.$storage.user_lon, methode: "nearest", user_lang: $rootScope.$storage.user_lang}, function(data){
                    $scope.accoms = data;
                    $rootScope.loading = false;
                });
            }

            getLocation();
        }])

    .controller('LangCtrl', ["$scope", "$rootScope", function($scope, $rootScope){
        $rootScope.pageclass = "langswitch";

        $rootScope.filterwarning = false;
        $scope.nwlang = $rootScope.$storage.user_lang;
        $scope.setLang = function(){
            $rootScope.$storage.user_lang = $scope.nwlang;
            $rootScope.changeLang($scope.nwlang);
        };
        $rootScope.loading = false;

    }])

    .controller('MapCtrl', ["$scope", "$rootScope", "uiGmapGoogleMapApi", "MyProvider","$sce", '$routeParams', function($scope, $rootScope, uiGmapGoogleMapApi, MyProvider, $sce, $routeParams){
        //TODO filter ook hier kunnen activeren
        $rootScope.pageclass = "maps";

        var filter = false;
        $rootScope.pageclass = "info";
        if($rootScope.$storage.filter){
            filter = JSON.stringify($rootScope.$storage.filtersettings);
            $rootScope.filterwarning = true;
        } else {
            $rootScope.filterwarning = false;
        }

        //$rootScope.$storage.filtersettings = JSON.parse( JSON.stringify( $rootScope.$storage.new_filtersettings ) );

        var isInt = function(value) {
            return !isNaN(value) &&
                parseInt(Number(value)) == value &&
                !isNaN(parseInt(value, 10));
        };

        var center = { latitude: 48.7900103, longitude: 7.5431379 };
        if($routeParams.lat && $routeParams.lon) {
            center= {latitude: $routeParams.lat, longitude: $routeParams.lon};
        }

        var zoom = 4;
        if($routeParams.zoom && isInt($routeParams.zoom)){
            zoom = parseInt($routeParams.zoom);
        }

        var setLocs = function(filter){
            MyProvider.arr.get({methode: "maps", user_lang: $rootScope.$storage.user_lang, filter: filter}, function(data){
                $scope.locs = data;
                if(filter){
                    $rootScope.$storage.locs_filtered = data;
                    $rootScope.$storage.locs_filtered_refreshdate = now;
                } else {
                    $rootScope.$storage.locs = data;
                    $rootScope.$storage.locs_refreshdate = now;
                }
                $rootScope.loading = false;
            });
        };

        var setMarkers = function(bounds, locs){
            var markers = [];

            var i = 0;
            while(locs[i]){
                if(bounds.southwest.latitude < locs[i]['lat'] && locs[i]['lat'] < bounds.northeast.latitude && bounds.southwest.longitude < locs[i]['lon'] && locs[i]['lon'] < bounds.northeast.longitude){

                    var iconclasses = "";
                    var ax = 0;
                    while (locs[i]['types'][ax]) {
                        iconclasses += '<span class="accoTypeIcons pictoDetaillist picto '+locs[i]['types'][ax].iconclass+'"></span>';
                        ax++;
                    }

                    var idKey = "id";
                    var marker = {
                        latitude: locs[i]['lat'],
                        longitude: locs[i]['lon'],
                        title: locs[i]['naam'],
                        foto: locs[i]['foto'],
                        icon: locs[i]['icon'],
                        types: iconclasses
                    };

                    marker[idKey] = locs[i]['id'];
                    markers.push(marker);
                }
                i++;
            }

            return markers;
        };

        $scope.locs = [];
        var now = Date.now();

        if(filter){
            //get filtered loc data
            if($rootScope.$storage.locs_filtered.length > 0){
                //are they still valid?
                if(now - $rootScope.$storage.locs_filtered_refreshdate > 86400000){
                    setLocs(filter);
                } else {
                    $scope.locs = $rootScope.$storage.locs_filtered;
                    $rootScope.loading = false;
                }
            } else {
                setLocs(filter);
            }
        } else {
            if($rootScope.$storage.locs.length > 0){
                if(now - $rootScope.$storage.locs_refreshdate > 86400000){
                    setLocs(filter);
                } else {
                    $scope.locs = $rootScope.$storage.locs;
                    $rootScope.loading = false;
                }
            } else {
                setLocs(filter);
            }
        }

        $scope.map = {
            center: center,
            zoom: zoom,
            events: {
                tilesloaded: function (map) {
                    $scope.$apply(function () {
                        var mapbounds = map.getBounds();
                        mapbounds = mapbounds.toUrlValue();
                        mapbounds = String(mapbounds).split(",");
                        $scope.map.bounds =
                        {
                            northeast : {
                                latitude : Number(mapbounds[2]),
                                longitude : Number(mapbounds[3])
                            },
                            southwest : {
                                latitude : Number(mapbounds[0]),
                                longitude : Number(mapbounds[1])
                            }
                        };

                        $scope.$watch('locs', function(){
                            $scope.map.markers = setMarkers($scope.map.bounds, $scope.locs);
                        });
                    });
                }
            },
            bounds: {},
            markers: [],
            markersEvents: {
                click: function(marker, eventName, model) {
                    $scope.map.window.model = marker.model;
                    $scope.map.window.info = marker.model;
                    $scope.map.window.types = marker.model.types;
                    $scope.map.window.types = $sce.trustAsHtml($scope.map.window.types);
                    $scope.$apply();
                    $scope.map.window.show = true;
                }
            },
            markerInfo: {},
            window: {
                info: {},
                marker: {},
                model: {},
                types: "",
                show: false,
                closeClick: function() {
                    this.show = false;
                },
                options: {} // define when map is ready
            }
        };


    }])

    .controller('AboutCtrl',["$scope", "$rootScope" ,function($scope, $rootScope){
        $rootScope.filterwarning = false;
        $rootScope.pageclass = "info";
        $scope.title = {};
        $scope.title.nl = 'Over GroeneVakantiegids.nl';
        $scope.title.en = 'About GreenHolidays';
        $scope.info = '<p>De Groene Vakantiegids is een initiatief van stichting ECEAT, een organisatie zonder winstoogmerk en met als doel duurzaam toerisme te bevorderen. Sinds haar lancering in 1992 heeft de Groene Vakantiegids een groeiend aantal van meer dan 1500 accommodaties in 28 landen opgebouwd.<br><br><span class="gvk_green">Doelstelling</span><br>Bij de Groene Vakantiegids staat het \'groener\' en milieuvriendelijker maken van een accommodatie voorop. Dit gaat hand in hand met het begrip \'ecotoerisme\'. Hierbij gaat het om kleinschalig toerisme zodat ook een volgende generatie van een plek kan genieten. Ecotoerisme heeft als doel dat milieu, natuur en landschap niet aangetast worden. Bovendien stimuleert ecotoerisme de plaatselijke economie. Uw bezoek aan een van onze accommodaties helpt daarbij!</p><p>Wij wensen u veel surfgenot met deze app en hopen dat u uw ideale vakantiebestemming er op zult vinden. Wij houden ons ook aanbevolen voor tips en suggesties om het gebruiksgemak te verbeteren</p>';
        $rootScope.loading = false;

    }])

    .controller('FilterCtrl',[ "$scope", "$rootScope", "$http", function ($scope, $rootScope, $http) {
        $rootScope.filterwarning = false;
        $rootScope.loading = false;

        $scope.isEmpty = function(obj) {
            if(obj === undefined) return true;
            return (JSON.stringify(obj) == "{}");
        };

        //make a copy of the current filtersettings and make changes on that object
        //after exiting the filterpage, we check if there is a difference between the old and new settings
        //if so we override the filtersettings with the new data and erase current data based on filtersettings
        $rootScope.$storage.new_filtersettings = JSON.parse( JSON.stringify( $rootScope.$storage.filtersettings ) );

        if(! $rootScope.$storage.hasOwnProperty("app_version")){
            //unversioned app had false-values in filter. We remove them here and change the app_version
            for(var obj in $rootScope.$storage.new_filtersettings){
                if($rootScope.$storage.new_filtersettings.hasOwnProperty(obj)) {
                    for (var f in $rootScope.$storage.new_filtersettings[obj]) {
                        if ($rootScope.$storage.new_filtersettings[obj].hasOwnProperty(f) && $rootScope.$storage.new_filtersettings[obj][f] == false) {
                            delete $rootScope.$storage.new_filtersettings[obj][f];
                        }
                    }
                }
            }
            $rootScope.$storage.app_version = appVersion;
        }

        $http.get('data/filter.json').then(function(response) {
            $scope.filterfields = response.data;
        });

    }]);