'use strict';

var appVersion = 1.1;

angular.module('GVGMobile', [
    'ngRoute',
    'ngResource',
    'nemLogging',
    'uiGmapgoogle-maps',
    //'ngTouch',
    'ngAnimate',
    'ngSanitize',
    'mobile-angular-ui',
    'mobile-angular-ui.gestures',
    'GVGMobile.controllers.Main',
    'ngStorage'
])

    .filter('orderObjectBy', function() {
        return function(items, field, reverse) {
            var filtered = [];
            angular.forEach(items, function(item) {
                filtered.push(item);
            });
            filtered.sort(function (a, b) {
                return (a[field] > b[field] ? 1 : -1);
            });
            if(reverse) filtered.reverse();
            return filtered;
        };
    })

    .config(function(uiGmapGoogleMapApiProvider) {
        uiGmapGoogleMapApiProvider.configure({
            key: 'AIzaSyC02Y9prXcHXE49jC8SqGIykgxeJUaXQ9I',
            v: '3.',
            libraries: 'drawing,geometry'
        });
    })

    .config(function($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain.  Notice the difference between * and **.
            '//groenevakantiegids.nl/**'
        ])
    })

    .config(function($routeProvider) {
        $routeProvider
            .when('/', {
                templateUrl: 'views/home.html?v='+appVersion,
                controller: 'HomeCtrl',
                reloadOnSearch: false
            })
            .when('/countries',{
                templateUrl: 'views/main.html?v='+appVersion,
                controller: 'MainCtrl',
                reloadOnSearch: false
            })
            .when('/near', {
                templateUrl: 'views/nearest.html?v='+appVersion,
                controller: 'NearCtrl',
                reloadOnSearch: false
            })
            .when('/contact', {
                templateUrl: 'views/contact.html?v='+appVersion,
                controller: 'ContactCtrl',
                reloadOnSearch: false
            })
            .when('/landen/:countryId', {
                templateUrl: 'views/regions.html?v='+appVersion,
                controller: 'MainCtrl',
                reloadOnSearch: false
            })
            .when('/regions/:regionId', {
                templateUrl: 'views/region.html?v='+appVersion,
                controller: 'RegionCtrl',
                reloadOnSearch: false
            })
            .when('/about', {
                templateUrl: 'views/info.html?v='+appVersion,
                controller: 'AboutCtrl',
                reloadOnSearch: false
            })
            .when('/accom/:accomId', {
                templateUrl: 'views/detail.html?v='+appVersion,
                controller: 'AccomCtrl',
                reloadOnSearch: false
            })
            .when('/map/:lat?/:lon?/:zoom?', {
                templateUrl: 'views/google_map.html?v='+appVersion,
                controller: 'MapCtrl',
                reloadOnSearch: false
            })
            .when('/filter', {
                templateUrl: 'views/filter.html?v='+appVersion,
                controller: 'FilterCtrl',
                reloadOnSearch: false
            })
            .when('/lang', {
                templateUrl: 'views/lang.html?v='+appVersion,
                controller: 'LangCtrl',
                reloadOnSearch: false
            })
            .otherwise({
                redirectTo: '/'
            });
    })

    .run(["$rootScope", "$http","$localStorage" ,function($rootScope, $http, $localStorage){

        if (!Date.now) {
            Date.now = function now() {
                return new Date().getTime();
            };
        }

        if(!Object.size) {
            Object.size = function(obj) {
                var size = 0, key;
                for (key in obj) {
                    if (obj.hasOwnProperty(key)) size++;
                }
                return size;
            };
        }

        $rootScope.changeLang = function(lng){
            $http.get('data/'+lng+'.json').then(function(response) {
                $rootScope.lang = response.data;
            });
        };

        $rootScope.filterwarning = false;

        $rootScope.$storage = $localStorage.$default({
            user_lang: "nl",
            filter: false,
            filtersettings: {},
            new_filtersettings: {},
            totals: [],
            totals_refreshdate: 0,
            totals_filtered: [],
            totals_filtered_refreshdate: 0,
            locs: [],
            locs_refreshdate: 0,
            locs_filtered: [],
            locs_filtered_refreshdate: 0,
            syncdate: null
        });

        //use this when selection of language is possible
        //$http.get('data/'+$rootScope.$storage.user_lang+'.json').success(function(data) {
        //    $rootScope.lang = data;
        //});
        $http.get('data/nl.json?v='+appVersion).then(function(response) {
            $rootScope.lang = response.data;
        });

        $rootScope.pageclass = "testclass";

        // Needed for the loading screen
        $rootScope.$on('$routeChangeStart', function(ev, next, current){
            try{
                if(typeof(current.templateUrl) !== 'undefined') {
                    if(current.templateUrl === 'views/filter.html?v='+appVersion){
                        if(JSON.stringify($rootScope.$storage.filtersettings) !== JSON.stringify($rootScope.$storage.new_filtersettings)){
                            $rootScope.$storage.filtersettings = $rootScope.$storage.new_filtersettings;
                            //TODO remove filter related data from storage
                            $rootScope.$storage.locs_filtered.length = 0;
                            $rootScope.$storage.totals_filtered.length = 0;
                        }
                    }
                }
            }catch(e){
                //console.log('current.templateUrl is undefined');
            }

            $rootScope.loading = true;
        });

    }])
    .directive('carousel', function(){
        return {
            restrict: 'C',
            scope: {},
            controller: function($scope) {
                this.itemCount = 0;
                this.activeItem = null;

                this.addItem = function(){
                    var newId = this.itemCount++;
                    this.activeItem = this.itemCount == 1 ? newId : this.activeItem;
                    return newId;
                };

                this.next = function(){
                    this.activeItem = this.activeItem || 0;
                    this.activeItem = this.activeItem == this.itemCount - 1 ? 0 : this.activeItem + 1;
                };

                this.prev = function(){
                    this.activeItem = this.activeItem || 0;
                    this.activeItem = this.activeItem === 0 ? this.itemCount - 1 : this.activeItem - 1;
                };
            }
        };
    })
    .directive('carouselItem', function($drag) {
        return {
            restrict: 'C',
            require: '^carousel',
            scope: {},
            transclude: true,
            template: '<div class="item"><div ng-transclude></div></div>',
            link: function(scope, elem, attrs, carousel) {
                scope.carousel = carousel;
                var id = carousel.addItem();

                var zIndex = function() {
                    var res = 0;
                    if (id === carousel.activeItem) {
                        res = 2000;
                    } else if (carousel.activeItem < id) {
                        res = 2000 - (id - carousel.activeItem);
                    } else {
                        res = 2000 - (carousel.itemCount - 1 - carousel.activeItem + id);
                    }
                    return res;
                };

                scope.$watch(function() {
                    return carousel.activeItem;
                }, function() {
                    elem[0].style.zIndex = zIndex();
                });

                $drag.bind(elem, {
                    //
                    // This is an example of custom transform function
                    //
                    transform: function(element, transform, touch) {
                        //
                        // use translate both as basis for the new transform:
                        //
                        var t = $drag.TRANSLATE_BOTH(element, transform, touch);

                        //
                        // Add rotation:
                        //
                        var Dx = touch.distanceX;
                        var t0 = touch.startTransform;
                        var sign = Dx < 0 ? -1 : 1;
                        var angle = sign * Math.min((Math.abs(Dx) / 700) * 30, 30);

                        t.rotateZ = angle + (Math.round(t0.rotateZ));

                        return t;
                    },
                    move: function(drag) {
                        if (Math.abs(drag.distanceX) >= drag.rect.width / 4) {
                            elem.addClass('dismiss');
                        } else {
                            elem.removeClass('dismiss');
                        }
                    },
                    cancel: function() {
                        elem.removeClass('dismiss');
                    },
                    end: function(drag) {
                        elem.removeClass('dismiss');
                        if (Math.abs(drag.distanceX) >= drag.rect.width / 4) {
                            scope.$apply(function() {
                                carousel.next();
                            });
                        }
                        drag.reset();
                    }
                });
            }
        };
    })
    .directive("bindCompiledHtml", function($compile) {
        return {
            template: '<div></div>',
            scope: {
                rawHtml: '=bindCompiledHtml'
            },
            link: function(scope, elem, attrs) {
                scope.$watch('rawHtml', function(value) {
                    if (!value) return;
                    // we want to use the scope OUTSIDE of this directive
                    // (which itself is an isolate scope).
                    var newElem = $compile(value)(scope.$parent);
                    elem.contents().remove();
                    elem.append(newElem);
                });
            }
        };
    });